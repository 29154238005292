<template>
  <main class="in-profile mb-5">
    <div class="mt-0 mt-sm-4 container">
      <b-card class="border-radius15 border-0 box-shaddow20" no-body>
        <b-tabs class="border-radius15" pills card :vertical="vertical">
          <b-tab title="اطلاعات کاربری" active>
              <ProfileUpdate v-if="$store.state.header.user"></ProfileUpdate>
              <div v-else class="d-flex justify-content-center align-items-center" style="min-height: 200px">
                <b-spinner style="width: 4rem; height: 4rem;color: #7570b3"></b-spinner>
              </div>
          </b-tab>
          <b-tab title="صورت حساب ها">
            <ProfileInvoices :invoices="invoices"></ProfileInvoices>
          </b-tab>
          <b-tab title="آدرس ها">
            <ProfileAddress></ProfileAddress>
          </b-tab>
          <b-tab title="محصولات مورد پسند من">
            <transition name="fade">
              <ProfileFavorites :favorites.sync="favorites"></ProfileFavorites>
            </transition>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </main>
</template>

<script>
import ProfileUpdate from "./component/profile/profileUpdate";
import ProfileAddress from "./component/profile/profileAddress";
import ProfileFavorites from "./component/profile/ProfileFavorites";
import ProfileInvoices from "./component/profile/ProfileInvoices";
export default {
  name: "profile",
  data() {
    return {
      vertical: !this.isMobile(),
      favorites: null,
      invoices: null,
    }
  },
  components: {ProfileInvoices, ProfileFavorites, ProfileAddress, ProfileUpdate},
  methods: {
    isMobile() {
      return window.innerWidth <= 768;
    },
    checkResponsive() {
      this.vertical = !this.isMobile()
    },
    async loadProfileData() {
      this.$store.commit('showLoading', false)
      try {
        let response = await this.$axios.get('/api/profile-page');
        this.favorites = response.data.data.favorites;
        this.invoices = response.data.data.invoices;

      } catch (error) {
        this.$root.error_notification(error);
        await this.$router.push('/');
      }
      this.$store.commit('hideLoading', false)
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkResponsive)
    this.loadProfileData();
  }
}
</script>

<style scoped>
.border-radius15 {
  border-radius: 15px!important;
}

</style>
<style>

.in-profile ul.nav.nav-pills {
  border-radius: 0 15px 15px 0!important;
}
.in-profile .card-header {
  border-radius: 15px 15px 0 0!important;
}
.in-profile .nav-pills .nav-link, .nav-pills .show > .nav-link {
  transition: 200ms all;
  text-align: right;
  border-radius: 10px !important;
  margin: 3px 0;
}

.in-profile legend {
  text-align: right;
}
</style>
