<template>
  <div class="container w-100">
    <div class="d-flex justify-content-start">
      <button @click="addressModal(1)" class="btn btn-primary border-radius10 btnThemeColor"><PlusCircleIcon style="width: 19px;vertical-align: bottom;"></PlusCircleIcon> اضافه کردن آدرس</button>
    </div>
    <AddressesMobile class="d-flex d-md-none" ref="addresses1" :selectable="false"></AddressesMobile>
    <AddressesDesktop class="d-none d-md-flex" ref="addresses2" :selectable="false"></AddressesDesktop>
  </div>
</template>

<script>
import AddressesMobile from "../address/AddressesMobile";
import AddressesDesktop from "../address/AddressesDesktop";
import PlusCircleIcon from "../svgs/PlusCircleIcon";
export default {
  name: "profileAddress",
  components: {PlusCircleIcon, AddressesDesktop, AddressesMobile},
  methods: {
    addressModal() {
      if (window.getComputedStyle(this.$refs['addresses1'].$el).display !== 'none') {
        this.$refs['addresses1'].addressModal();
      } else {
        this.$refs['addresses2'].addressModal();
      }
    }
  }
}
</script>

<style scoped>

</style>