<template>
  <b-form @submit.prevent="submitProfile">
    <b-alert class="text-right" variant="info" show>
      <Info style="width: 22px;fill: #597883;"></Info> این بخش مربوط به اطلاعات هویتی شما است.
    </b-alert>
    <b-row>
      <b-col md="4">
        <b-form-group>
          <template v-slot:label>
            نام <span class="text-danger">*</span>
          </template>
          <b-form-input :value="$store.state.header.user.first_name" name="first_name"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <template v-slot:label>
            نام خانوادگی <span class="text-danger">*</span>
          </template>
          <b-form-input :value="$store.state.header.user.last_name" name="last_name"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <template v-slot:label>
            کد ملی 
          </template>
          <b-form-input :value="$store.state.header.user.national_code" name="national_code"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <template v-slot:label>
            شماره کارت 
          </template>
          <b-form-input :value="$store.state.header.user.cart_number" name="cart_number"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <template v-slot:label>
            ایمیل 
          </template>
          <b-form-input :value="$store.state.header.user.email" type="email" name="email"></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-center">
      <b-btn :disabled="sendingRequest" type="submit" variant="primary" class="btnThemeColor">ثبت تغییرات</b-btn>
    </div>
  </b-form>

</template>

<script>
import Info from "../../component/svgs/Info";

export default {
  name: "profileUpdate",
  components: {
    Info
  },
  data() {
    return {
      sendingRequest: false,
    }
  },
  computed: {
    isProfileComplete() {
      return this.$store.state.header.user && this.$store.state.header.user.first_name;
    }
  },

  methods: {
    async submitProfile(e) {
      this.sendingRequest = true;
      let formData = new FormData(e.target);
      formData.set('_method', 'PUT');
      try {
        let response = await this.$axios.post('/api/profile', formData);
        this.$store.commit('header/setUser', response.data.data);
        this.$root.success_notification(response.data.message);
      } catch (error) {
        this.$root.error_notification(error);
      }
      this.sendingRequest = false;
    }
  }

}
</script>

<style scoped>

</style>